import { logger } from '../../utils/logger';
import { ChatbotData } from './types';

type Field = { id: string; value: string | number | boolean };

const ZenFieldId: Partial<Record<keyof ChatbotData, { us: string; eu: string }>> = {
    harverAccountId: { eu: '21626905468945', us: '25970485647511' },
    harverAccountName: { eu: '22456213810577', us: '25970469063319' },
    harverApplicantDetailPage: { eu: '22456523317393', us: '25971022103575' },
    harverApplicationEmail: { eu: '22456278645649', us: '25970680114071' },
    harverApplicationId: { eu: '21626935756817', us: '25971052309271' },
    harverApplicationLanguage: { eu: '22456419208465', us: '25970763736215' },
    harverApplicantName: { eu: '22456473939601', us: '25970641876631' },
    currentPage: { eu: '22455229906321', us: '25975669453079' },
    harverJourneyId: { eu: '22456503785873', us: '25975163857431' },
    harverJourneyStepId: { eu: '22456592995985', us: '25975604490391' },
    pageUrl: { eu: '22456080589201', us: '25976172298775' },
    harverVacancyId: { eu: '21626926347537', us: '25975684615063' },
};

export const toZendeskConversationFields = (hostname: string, data: ChatbotData): Field[] => {
    const key = hostname === 'my.harver-us.com' ? 'us' : 'eu';
    // Safe dynamic object creation, key and field are hard coded
    // eslint-disable-next-line security/detect-object-injection
    return Object.entries(ZenFieldId).map(([field, ids]) => ({ id: ids[key], value: data[field] }));
};

const Ze = (...args: unknown[]): void => {
    if (!('zE' in window)) {
        const msg =
            'An attempt to use Zendesk was detected.' +
            'Though there is no instance available.' +
            'Nothing will happen.';
        logger.warn(msg);
        return;
    }

    try {
        (window.zE as (...args: unknown[]) => void)(...args);
    } catch (err) {
        logger.error('Error calling zendesk api', err, { args: args as never });
    }
};

/* Public API to interact with Zendesk client after script is downloaded */

export const OpenChatbot = () => Ze('messenger', 'open');

export const SetConversationalFields = (fields: Field[]) => Ze('messenger:set', 'conversationFields', fields);
